import './src/style/style.scss';

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {

  const { pathname } = location;
  const routes = ['/', '/en', '/en/']

  if(routes.indexOf(pathname) !== -1) {
    return false;
  }

  return true;
};

